<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Testimonials</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Testimonials"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.testimonialForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Testimonial</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="testimonials"
            no-data-text="No testimonials"
            :items-per-page="-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-lhl-testimonials-individual',
                  params: { testimonialId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                bottom
                x-small
                depressed
                color="red lighten-4 red--text"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      scrollable
      v-model="deleteTestimonial.dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Archive Testimonial</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteTestimonial.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TestimonialForm ref="testimonialForm" />
  </div>
</template>

<script>
import TestimonialForm from "./components/TestimonialForm";

export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "title" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteTestimonial: {
        dialog: false,
        testimonial: {},
        loading: false,
      },
    };
  },

  components: {
    TestimonialForm,
  },

  computed: {
    testimonials() {
      let testimonials = this.$store.getters["lhl/testimonials/all"];

      if (this.searchTerm !== "") {
        testimonials = testimonials.filter((c) => {
          const name = c.title.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return testimonials;
    },
  },

  methods: {
    openDelete(testimonial) {
      this.deleteTestimonial.testimonial = testimonial;
      this.deleteTestimonial.dialog = true;
    },

    resetDelete() {
      this.deleteTestimonial.dialog = false;
      this.deleteTestimonial.testimonial = {};
      this.deleteTestimonial.loading = false;
    },

    saveDelete() {
      this.deleteTestimonial.loading = true;

      this.$store
        .dispatch("lhl/testimonials/deleteTestimonial", {
          appId: this.$route.params.id,
          testimonialId: this.deleteTestimonial.testimonial.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteTestimonial.loading = false;
        });
    },
  },
};
</script>
